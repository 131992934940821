<template>
    <div class="c-contact-form">
        <el-form
            :model="leadForm"
            :rules="leadRules"
            ref="leadForm"
            label-width="auto"
            class="c-leadForm"
        >
            <el-form-item label="Art der Anfrage">
                <el-select
                    @change="
                        $refs.leadForm.clearValidate([
                            'newCarModel',
                            'car.model'
                        ])
                    "
                    v-model="leadForm.task.type"
                    placeholder="Select"
                >
                    <el-option
                        label="Allgemein"
                        value="GENERAL_REQUEST"
                    ></el-option>
                    <el-option label="Probefahrt" value="TESTDRIVE">
                    </el-option>
                    <el-option
                        v-if="fromComponent !== 'fahrzeug'"
                        label="Servicetermin"
                        value="VEHICLE_REQUEST"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Anrede" prop="person.salutation">
                <el-radio-group v-model="leadForm.person.salutation">
                    <el-radio label="Herr"></el-radio>
                    <el-radio label="Frau"></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="Vorname" prop="person.firstName">
                <el-input v-model="leadForm.person.firstName"></el-input>
            </el-form-item>
            <el-form-item label="Nachname" prop="person.lastName">
                <el-input v-model="leadForm.person.lastName"></el-input>
            </el-form-item>
            <el-form-item label="Adresse" prop="person.street">
                <el-input v-model="leadForm.person.street"></el-input>
            </el-form-item>
            <el-form-item label="PLZ" prop="person.zipCode">
                <el-input
                    v-model="leadForm.person.zipCode"
                    type="number"
                ></el-input>
            </el-form-item>
            <el-form-item label="Ort" prop="person.city">
                <el-input v-model="leadForm.person.city"></el-input>
            </el-form-item>
            <el-form-item label="Telefon" prop="person.mobilePrivate">
                <el-input
                    v-model="leadForm.person.mobilePrivate"
                    type="tel"
                ></el-input>
            </el-form-item>
            <el-form-item label="E-Mail" prop="person.emailPrivate">
                <el-input
                    v-model="leadForm.person.emailPrivate"
                    type="email"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="Fahrzeug"
                prop="newCarModel"
                v-if="
                    fromComponent !== 'fahrzeug' &&
                        leadForm.task.type === 'TESTDRIVE'
                "
            >
                <el-select
                    v-model="leadForm.newCarModel"
                    placeholder="Bitte wählen"
                >
                    <el-option-group
                        v-for="group in newCars"
                        :key="group.label"
                        :label="group.label"
                    >
                        <el-option
                            v-for="item in group.options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-option-group>
                </el-select>
            </el-form-item>
            <template v-if="leadForm.task.type === 'VEHICLE_REQUEST'">
                <el-form-item label="Fahrzeug" prop="car.model">
                    <el-input
                        v-model="leadForm.car.model"
                        type="text"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Kennzeichen" prop="numberPlate">
                    <el-input
                        v-model="leadForm.numberPlate"
                        type="text"
                    ></el-input>
                </el-form-item>
            </template>
            <template v-if="leadForm.task.type !== 'GENERAL_REQUEST'">
                <el-form-item label="Wunschdatum">
                    <el-date-picker
                        v-model="leadForm.dueDate"
                        type="date"
                        placeholder="Datum wählen"
                        format="dd.MM.yyyy"
                        value-format="dd.MM.yyyy"
                        :clearable="false"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="Uhrzeit">
                    <el-time-select
                        v-model="leadForm.dueTime"
                        :picker-options="{
                            start: '08:00',
                            step: '00:15',
                            end: '17:00'
                        }"
                        :clearable="false"
                        placeholder="Select time"
                    >
                    </el-time-select>
                </el-form-item>
            </template>
            <!-- <template v-if="leadForm.task.type === 'VEHICLE_REQUEST'">
                <el-form-item label="Modell" prop="person.modell">
                    <el-input v-model="leadForm.person.modell"></el-input>
                </el-form-item>
                <el-form-item label="Kennzeichen" prop="person.kennzeichen">
                    <el-input v-model="leadForm.person.kennzeichen"></el-input>
                </el-form-item>
            </template> -->
            <el-form-item label="Ihre Nachricht" prop="interest.comment">
                <el-input
                    type="textarea"
                    v-model="leadForm.interest.comment"
                ></el-input>
            </el-form-item>
            <el-form-item prop="generalPrivacy" label-width="0">
                <el-checkbox v-model="leadForm.generalPrivacy"
                    >Einverständnis über die Datenverarbeitung der oben
                    angegebenen Daten ausschließlich zum Zwecke der internen
                    Weiterverarbeitung meiner Anfrage.</el-checkbox
                >
            </el-form-item>
            <el-button
                type="primary"
                @click="createLead('leadForm')"
                class="center"
                >Anfragen</el-button
            >
        </el-form>
    </div>
</template>

<script>
import { saveLead, getNewCars } from "@/api/cars";

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const getDefaultLeadForm = () => {
    return {
        // additional fields, because rules would not function correctly for example in person.privacy[0].generalPrivacy
        dueTime: "8:00",
        generalPrivacy: false,
        numberPlate: "",
        newCarModel: "",

        car: {
            model: ""
        },
        dueDate: "",
        interest: {
            comment: ""
        },
        person: {
            salutation: "",
            firstName: "",
            street: "",
            zipCode: "",
            city: "",
            mobilePrivate: "",
            emailPrivate: ""
            // kennzeichen: "",
        },
        task: {
            type: "GENERAL_REQUEST"
        }
    };
};

export default {
    props: {
        fromComponent: {
            type: String,
            required: true
        },
        car: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        }
    },
    watch: {
        car: {
            handler() {
                if (this.car["Wagen-Nr."]) {
                    this.leadForm.interest.comment =
                        "Guten Tag, ich interessiere mich für das Fahrzeug mit der Wagen-Nummer " +
                        this.car["Wagen-Nr."] +
                        " und bitte um Kontaktaufnahme.";
                }
            },
            deep: true
        }
    },
    mounted() {
        const taskType = getParameterByName("art", window.location.href);
        if (taskType === "probefahrt") {
            this.leadForm.task.type = "TESTDRIVE";
            const newCarModel = getParameterByName(
                "modell",
                window.location.href
            );
            if (newCarModel) {
                this.leadForm.newCarModel = newCarModel;
            }
        } else if (taskType === "servicetermin") {
            this.leadForm.task.type = "VEHICLE_REQUEST";
        }
        getNewCars().then(response => {
            const result = response.data.result;
            if (response.data.status === "success" && result) {
                let counter = 0;
                for (let resultKey in result) {
                    let brand = result[resultKey].name;
                    this.newCars.push({ label: brand, options: [] });
                    for (let carKey in result[resultKey].cars) {
                        this.newCars[counter].options.push({
                            value:
                                brand +
                                " " +
                                result[resultKey].cars[carKey].name,
                            label: result[resultKey].cars[carKey].name
                        });
                    }
                    counter++;
                }
            }
        });
    },
    methods: {
        createLead(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    let carDetails = {};
                    if (Object.keys(this.car).length > 0) {
                        let brand = this.car["Marken-ID"].toUpperCase().replace("-", "_");
                        if(brand === "RENAULT_ALPINE"){
                            brand = "RENAULT_APINE";
                        }
                        carDetails = {
                            brand: brand,
                            model: this.car["Name Modell Type"],
                            priceMax: this.car["Preis"],
                            vehicleNumber: this.car["Fahrzeug-ID"],
                            vehicleType: this.car["Fahrzeugtyp-ID"]
                        };
                    } else if (this.leadForm.task.type === "TESTDRIVE") {
                        carDetails.model = this.leadForm.newCarModel;
                    }
                    this.lead.interest = {
                        ...this.lead.interest,
                        ...this.leadForm.interest,
                        ...carDetails
                    };
                    this.lead.car = {
                        ...this.lead.car,
                        ...this.leadForm.car
                    };
                    this.lead.person = {
                        ...this.lead.person,
                        ...this.leadForm.person
                    };
                    this.lead.task = {
                        ...this.lead.task,
                        ...this.leadForm.task
                    };
                    // this.lead.dueDate = this.leadForm.dueDate;
                    this.lead.person.privacy[0].generalPrivacy = this.leadForm.generalPrivacy;
                    if (this.lead.task.type === "GENERAL_REQUEST") {
                        this.lead.task.text = "Allgemeine Anfrage";
                    } else if (this.lead.task.type === "TESTDRIVE") {
                        this.lead.task.text = "Probefahrt Anfrage";
                    } else if (this.lead.task.type === "VEHICLE_REQUEST") {
                        this.lead.task.text = "Servicetermin Anfrage";
                        this.lead.interest.comment +=
                            "<br>Kennzeichen: " + this.leadForm.numberPlate;
                    }
                    if (
                        this.lead.task.type === "TESTDRIVE" ||
                        this.lead.task.type === "VEHICLE_REQUEST"
                    ) {
                        this.lead.interest.comment +=
                            "<br>Wunschdatum: " +
                            this.leadForm.dueDate +
                            "<br>Uhrzeit: " +
                            this.leadForm.dueTime;
                    }
                    this.$store.commit("setIsLoading", true);
                    saveLead({ lead: this.lead }).then(response => {
                        if (response.data.Status === "Success") {
                            this.$alert(
                                "Vielen Dank für Ihre Anfrage. Ihre Nachricht wurde erfolgreich versandt.",
                                "Anfrage erfolgreich",
                                {
                                    confirmButtonText: "OK",
                                    callback: () => {
                                        this.$refs[formName].resetFields();
                                    }
                                }
                            );
                        }
                    });
                } else {
                    console.log("error submit!");
                    return false;
                }
            });
        }
    },
    data() {
        var validateGeneralPrivacy = (rule, value, callback) => {
            if (value !== true) {
                callback(
                    new Error("Bitte Datenschutzbestimmungen akzeptieren")
                );
            } else {
                callback();
            }
        };
        return {
            leadForm: getDefaultLeadForm(),
            newCars: [],
            leadRules: {
                generalPrivacy: [
                    {
                        validator: validateGeneralPrivacy,
                        trigger: "change"
                    }
                ],
                newCarModel: [
                    {
                        required: true,
                        message: "Bitte Fahrzeug auswählen",
                        trigger: "change"
                    }
                ],
                car: {
                    model: [
                        {
                            required: true,
                            message: "Bitte Fahrzeug inkl. Modell eingeben",
                            trigger: "blur"
                        }
                    ]
                },
                numberPlate: [
                        {
                            required: true,
                            message: "Bitte Kennzeichen des Fahrzeugs eingeben",
                            trigger: "blur"
                        }
                ],
                person: {
                    salutation: [
                        {
                            required: true,
                            message: "Bitte Anrede auswählen",
                            trigger: "change"
                        }
                    ],
                    firstName: [
                        {
                            required: true,
                            message: "Bitte Vornamen eingeben",
                            trigger: "blur"
                        }
                    ],
                    lastName: [
                        {
                            required: true,
                            message: "Bitte Nachnamen eingeben",
                            trigger: "blur"
                        }
                    ],
                    // street: [
                    //     {
                    //         required: true,
                    //         message: "Bitte Straße eingeben",
                    //         trigger: "blur"
                    //     }
                    // ],
                    // zipCode: [
                    //     {
                    //         required: true,
                    //         message: "Bitte Postleitzahl eingeben",
                    //         trigger: "blur"
                    //     }
                    // ],
                    // city: [
                    //     {
                    //         required: true,
                    //         message: "Bitte Stadt eingeben",
                    //         trigger: "blur"
                    //     }
                    // ],
                    mobilePrivate: [
                        {
                            required: true,
                            message: "Bitte Telefonnummer eingeben",
                            trigger: "blur"
                        }
                    ],
                    emailPrivate: [
                        {
                            required: true,
                            message: "Bitte E-Mail Adresse eingeben",
                            trigger: "blur"
                        }
                    ]
                }
            },
            lead: {
                additionalText: "",
                brand: "NO_BRAND",
                car: {
                    brand: "NO_BRAND",
                    fuelType: "",
                    gearboxType: "",
                    manufactureYear: 0,
                    model: "",
                    registrationDate: "",
                    url: "",
                    vin: ""
                },
                dueDate: "",
                interest: {
                    brand: "NO_BRAND",
                    comment: "",
                    model: "",
                    priceMax: 0,
                    priceMin: 0,
                    vehicleNumber: "0_0-00",
                    vehicleType: ""
                },
                location: 0,
                person: {
                    birthdate: "",
                    branch: "",
                    city: "",
                    company: "",
                    emailPrivate: "",
                    emailWork: "",
                    firstName: "",
                    lastName: "",
                    mobilePrivate: "",
                    mobileWork: "",
                    phonePrivate: "",
                    phoneWork: "",
                    privacy: [
                        {
                            brand: "NO_BRAND",
                            email: true,
                            generalPrivacy: false,
                            letter: false,
                            phone: true,
                            sms: false
                        }
                    ],
                    salutation: "",
                    street: "",
                    titlePrefix: "",
                    titleSuffix: "",
                    uidNummer: "",
                    zipCode: ""
                },
                priority: "",
                source: window.location.host,
                sourceDetail: window.location.href,
                task: {
                    attainable: "",
                    date: "",
                    text: "",
                    type: ""
                }
            }
        };
    }
};
</script>

<style scoped lang="scss">
.c-contact-form {
    margin-right: 200px;
    margin-left: 200px;

    @media screen and (max-width: $breakpoint-xl) {
        margin-right: 100px;
        margin-left: 100px;
    }

    @media screen and (max-width: $breakpoint-md) {
        margin-right: 0;
        margin-left: 0;
    }
}
</style>

<style lang="scss">
.c-contact-form {
    .el-checkbox {
        &__input {
            position: absolute;
            top: 2px;
            left: 0;
        }
        &__label {
            white-space: normal;
            padding-left: 30px;
        }
    }
}
</style>
